import React from 'react';
import { FormRecipe } from '../index';

export function AddFormRecipe(props) {
 
  return (
    <FormRecipe 
      onSubmit={props.onSubmit}
      lib={props.lib}
      copyIngredient={props.copyIngredient}
      selectMeasure={props.selectMeasure}
      advice={props.advice}
      handleCheckChange={props.handleCheckChange}
      activeCooking={props.activeCooking}
      heat={props.heat}
      fresh={props.fresh}
      preparation={props.preparation}
      addIngredient={props.addIngredient}
      isOpenIng={props.isOpenIng}
      onCloseModalIng={props.onCloseModalIng}
      title={props.title}
      onClickDesc={props.onClickDesc}
      handleDeleteImgDesc={props.handleDeleteImgDesc}
      imageDesc={props.imageDesc} 
      kitchenware= {props.kitchenware}
      category={props.category}
      hours={props.hours}
      minute={props.minute}
      author={props.author}
      onChange={props.onChange}
      categoryFood={props.categoryFood}
      step={props.step}
      handleCountClickMinus={props.handleCountClickMinus}
      handleCountClickPlus={props.handleCountClickPlus}
      isDisabled={props.isDisabled}
      capacity={props.capacity}
      ingredientsData={props.ingredientsData}
      handleDelete={props.handleDelete}
      filtrMassiv={props.filtrMassiv}
      handleChangeInput={props.handleChangeInput}
      handleAutoText={props.handleAutoText}
      hidden={props.hidden}
      ingredient={props.ingredient}
      measure={props.measure}
      weight={props.weight}
      handleChangeMeasure={props.handleChangeMeasure}
      handleChangeInputWeight={props.handleChangeInputWeight}
      handleFocus={props.handleFocus}
      choiceIngredient={props.choiceIngredient}
      handleAddIng={props.handleAddIng}
      types={props.types}
      handleCheckBox={props.handleCheckBox}
      handleChangeDesc={props.handleChangeDesc}
      desc={props.desc}
      handleDeleteStep={props.handleDeleteStep}
      handleNewStep={props.handleNewStep}
      isOpen={props.isOpen}
      descStep={props.descStep}
      onClose={props.onClose}
      imageBlob={props.imageBlob}
      handleDeleteHeritageImage={props.handleDeleteHeritageImage}
      onClick={props.onClick}
      head={props.head}
      submitDisabled={props.submitDisabled}
      ingError={props.ingError}
      weightError={props.weightError}
      addIngDisabled={props.addIngDisabled}
      titleError={props.titleError}
      descError={props.descError}
      imageError={props.imageError}
      vitamins={props.vitamins}
    />
  )
}
