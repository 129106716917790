import { createSlice } from '@reduxjs/toolkit';
import { 
  getIngredients, 
  deleteIngredients, 
  editIngredients, postRecipe, 
  editRecipe, 
  getRecipe, 
  deleteRecipe, 
  postIngredients, 
  getRecipeId,
  getLibrary,
  postLibrary,
  deleteLibrary,
  updateLibrary,
  getPlan,
  fetchFilter,
  getUsersPage,
  editVisor,
} from './api.js'

export const reguestSlice = createSlice({
    name: 'ingredients',
    initialState: {
      ingredients: [],
      status: null,
      error: null,
      statusPost: null,
      errorPost: null,
    },
    reducers: {},
    extraReducers: {
      [getIngredients.pending]: (state) => {
        state.status = 'loading';
        state.error = null;
      },
      [getIngredients.fulfilled]: (state, action)=> {
        state.status = 'resolved'
        state.ingredients = action.payload;
      },
      [postIngredients.pending]: (state) => {
        state.statusPost = 'loading';
        state.errorPost = null;
      },
      [postIngredients.fulfilled]: (state, action)=> {
        state.statusPost = 'resolved'
        state.ingredients.push(action.payload)
      },
      [postIngredients.rejected]: (state, action)=> {
        state.statusPost = 'rejected'
        state.errorPost = action.payload
      },
      [deleteIngredients.fulfilled]: (state, action)=> {
        state.sts = 'resolved'
        state.ingredients = state.ingredients.filter(ingredients => ingredients._id !== action.payload._id);
      },
      [editIngredients.fulfilled]: (state, action)=> {
        state.sts = 'resolved'
        state.ingredients = state.ingredients.map(ingredients => ingredients._id === action.payload._id ? action.payload : ingredients);
      }, 
      [getIngredients.rejected]: (state, action)=> {
        state.status = 'rejected'
        state.ingredients = action.payload;
      },
    }
  })
  
export const reguestRecipeIdSlice = createSlice({
    name: 'recipe',
    initialState: {
      recipe: [],
      status: null,
      error: null,
    },
    reducers: {},
    extraReducers: {
      [getRecipeId.pending]: (state) => {
        state.status = 'loading';
        state.error = null;
       console.log(state)
      },
      [getRecipeId.rejected]: (state, action)=> {
        state.status = 'rejected'
        state.recipe = action.payload;
      },
      [getRecipeId.rejected]: (state, action)=> {
        state.status = 'rejected'
        state.recipe = action.payload;
      },

    }
  })

  export const reguestGetSlice = createSlice({
    name: 'recipes',
    initialState: {
      recipes: [],
      recipePage: [],
      totalCount: 0,
      sts: null,
      error: null,
      fetching: null,
    },
    reducers: {},
    extraReducers: {
      [getRecipe.pending]: (state) => {
        state.sts = 'loading';
        state.error = null;
      },
      [getRecipe.fulfilled]: (state, action)=> {
        state.sts = 'resolved'
        state.recipes = action.payload;
      },
      [postRecipe.fulfilled]: (state, action)=> {
        state.sts = 'resolved'
        state.recipes.push(action.payload)
        console.log(action)
      },
      [deleteRecipe.fulfilled]: (state, action)=> {
        state.sts = 'resolved'
        state.recipes = state.recipes.filter(recipe => recipe._id !== action.payload._id);
      },
      [editRecipe.fulfilled]: (state, action)=> {
        state.sts = 'resolved'
        state.recipes = state.recipes.map(recipe => recipe._id === action.payload._id ? action.payload : recipe);
      },
      [getRecipe.rejected]: (state, action)=> {
        state.sts = 'rejected'
        state.recipes = action.payload;
      },
      [fetchFilter.pending]: (state) => {
        state.fetching = 'loading';
        state.errorPage = null;
      },
      [fetchFilter.fulfilled]: (state, action)=> {
        state.fetching = 'resolved' 
        state.totalCount = Number(action.payload.count)
        state.recipePage = action.payload.data
      },
    }
  })

  export const reguestUsersSlice = createSlice({
    name: 'users',
    initialState: {
      users: [],
      totalCount: 0,
      statusUser: null,
      errorUser: null,
    },
    reducers: {},
    extraReducers: {
      [getUsersPage.pending]: (state) => {
        state.statusUser = 'loading';
        state.errorUser = null; 
      },
      [getUsersPage.rejected]: (state, action)=> {
        state.statusUser = 'rejected' 
      },
      [getUsersPage.fulfilled]: (state, action)=> {
        state.statusUser = 'resolved'
        state.users = action.payload.data
        state.totalCount = Number(action.payload.count)
      },
      [editVisor.pending]: (state) => {
        state.statusUser = 'loading';
        state.errorUser = null; 
      }, 
      [editVisor.fulfilled]: (state, action)=> {
        state.statusUser = 'resolved'
        state.users = state.users.map(user => user._id === action.payload._id ? action.payload : user);
      },

    }
  })

  export const reguestLibrarySlice = createSlice({
    name: 'lib',
    initialState: {
      lib: [],
      status: null,
      error: null,
      statusPost: null,
      errorPost: null,
    },
    reducers: {},
    extraReducers: {
      [getLibrary.pending]: (state) => {
        state.status = 'loading';
        state.error = null;
      },
      [getLibrary.fulfilled]: (state, action)=> {
        state.status = 'resolved' 
        state.lib = action.payload;
      },
      [postLibrary.pending]: (state) => {
        state.statusPost = 'loading';
        state.errorPost = null;
      },
      [postLibrary.fulfilled]: (state, action)=> {
        state.statusPost = 'resolved'
        console.log(action.payload)
        state.lib = action.payload
      },
      [deleteLibrary.fulfilled]: (state, action)=> {
        state.sts = 'resolved'
        state.lib = action.payload
      },
      [postLibrary.rejected]: (state, action)=> {
        state.statusPost = 'rejected'
        state.errorPost = action.payload
      },
      [updateLibrary.fulfilled]: (state, action)=> {
        state.sts = 'resolved'
        state.lib = action.payload
      },
    }
  })

   //Работа с планом питания
   export const reguestPlanSlice = createSlice({
    name: 'plans',
    initialState: {
      plans: [], 
      statusPlan: null,
      error: null,
      creature: false
    },
    reducers: {
    },
    extraReducers: {
      [getPlan.pending]: (state) => {
        state.statusPlan = 'loading';
        state.error = null;
        state.creature = true
      },
      [getPlan.fulfilled]: (state, action)=> {
        state.statusPlan = 'resolved'
        state.plans = action.payload;
        state.creature = false
      }, 
    }
  })



