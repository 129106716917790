import React from 'react';
import { FormRecipe } from '../index';

export function EditFormRecipe (props) {
  
  return (
    <FormRecipe
    onSubmit={props.onSubmit}
    vitamins={props.vitamins}
    title={props.title}
    advice={props.advice}
    heat={props.heat}
    fresh={props.fresh}
    copyIngredient={props.copyIngredient}
    handleCheckChange={props.handleCheckChange}
    selectMeasure={props.selectMeasure}
    onClickDesc={props.onClickDesc}
    handleDeleteImgDesc={props.handleDeleteImgDesc}
    imageDesc={props.imageDesc} 
    activeCooking={props.activeCooking}
    preparation={props.preparation}
    kitchenware= {props.kitchenware}
    category={props.category}
    hours={props.hours}
    minute={props.minute}
    author={props.author}
    onChange={props.onChange}
    categoryFood={props.categoryFood}
    step={props.step}
    handleCountClickMinus={props.handleCountClickMinus}
    handleCountClickPlus={props.handleCountClickPlus}
    isDisabled={props.isDisabled}
    capacity={props.capacity}
    lib={props.lib}
    ingredientsData={props.ingredientsData}
    handleDelete={props.handleDelete}
    filtrMassiv={props.filtrMassiv}
    handleChangeInput={props.handleChangeInput}
    handleAutoText={props.handleAutoText}
    hidden={props.hidden}
    ingredient={props.ingredient}
    measure={props.measure}
    weight={props.weight}
    handleChangeMeasure={props.handleChangeMeasure}
    handleChangeInputWeight={props.handleChangeInputWeight}
    handleFocus={props.handleFocus}
    choiceIngredient={props.choiceIngredient}
    handleAddIng={props.handleAddIng}
    addIngredient={props.addIngredient}
    types={props.types}
    handleCheckBox={props.handleCheckBox}
    handleChangeDesc={props.handleChangeDesc}
    desc={props.desc}
    handleDeleteStep={props.handleDeleteStep}
    handleNewStep={props.handleNewStep}
    isOpen={props.isOpen}
    descStep={props.descStep}
    onClose={props.onClose}
    imageBlob={props.imageBlob}
    handleDeleteHeritageImage={props.handleDeleteHeritageImage}
    onClick={props.onClick}
    head={props.head}
    submitDisabled={props.submitDisabled}
    ingError={props.ingError}
    weightError={props.weightError}
    addIngDisabled={props.addIngDisabled}
    titleError={props.titleError}
    descError={props.descError}
    imageError={props.imageError}
    />
)
}