import React from 'react';
import {UsersTable, Settings, Search} from '../../organisms'
import { useSelector, useDispatch } from 'react-redux'; 
import SignUp from '../SignUp/SignUp'
import {getUsersPage} from '../../../store/api.js'
import { ModalContainer } from '../../atoms' 

export function Users(props) {
  const {users, totalCount, statusUser} = useSelector(state => state.users)
  const { plans } = useSelector(state => state.plans)
  const dispatch= useDispatch()
  const limit = 10
  const [sort, setSort] = React.useState(false) 
  const allPages = Math.ceil(totalCount/limit) // Все карточки разделенные на количество карточек на странице
  const pages = Array.from({length: allPages}, (_, i) => i + 1)
  const [currentPage, setCurrentPage] = React.useState(1) 
  const [isOpen, setIsOpen] = React.useState(false) 
  const [user, setUser] = React.useState({})
  const [keyword, setKeyword] = React.useState('')
  console.log(sort)
 
  React.useEffect(()=> { 
    if(currentPage) { 
      dispatch(getUsersPage({currentPage, limit, sort}))
    } 
  },[currentPage, sort])

  //Счетчик изменения страниц
 function handleCounter(page) {
  setCurrentPage(page)
} 

function handleSettings(user) {
  setIsOpen(true)
  setUser(user)
}
function hanleClose () {
  setIsOpen(false)
  
}

function handleSearchDish (e) {
  setKeyword(e.target.value);
}

function handleSort () { 
  setSort(!sort)
}


  return (
    <main className='content'>  
    <p>Число пользователей: {totalCount} Общее количество планов: {plans.length}</p>
    <Search onSearch={handleSearchDish} keyword={keyword} text='по рецептам' placeholder='Искать по названию'/>

    <div className='table'>
      <div className='table__thead'>
        <ul className='table__tr_recipe'>
          <li className='table__th'>Имя/Email</li> 
          <li className='table__th'>Подписка</li>
          <li className='table__th'>Доступ</li>
          <li className='table__th'>Даты<span onClick={handleSort} className='gg-sort item__link'></span></li>
          <li className='table__th'>Баланс/Бонусы</li>
          <li className='table__th'>Рецепты/Планы</li> 
          <li className='table__th'>Настройки</li>
         
        </ul>
      </div>
      <div>
      {statusUser === 'resolved' &&
        users.map((user)=> (
          <UsersTable 
            user={user} 
            nVisorUser={props.onVisorUser} 
            onAdminUser={props.onAdminUser} 
            onUpdateUser={props.onUpdateUser} 
            onAccesUser={props.onAccesUser}
            onDeleteUser={props.onDeleteUser} 
            key={user._id}
            onVisorUser={props.onVisorUser}
            onEditUserData={props.onEditUserData}
            onSubmit={props.onSubmit}
            handleSettings={handleSettings}
           />
       ))
     }
         
      </div>
    </div>
    { Object.values(user).length > 0 &&
      <ModalContainer 
        isOpen={isOpen}
        onClose={hanleClose}
        title='settings'
      > 
       <Settings type='plans' user={user} /> 
      </ModalContainer>
    }
    <div className='pages'>
      {pages.map((page, index)=> 
        <span 
          className={`page__number ${currentPage === page ? 'page__current' : ''}`}
          key={index} 
          onClick={()=>handleCounter(page)}>{page}</span>
      )}
    </div>
    <SignUp onRegister={props.onRegister} />
  </main>
  )
   
}