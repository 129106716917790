import React from 'react';
import './UsersTable.css';
import { CurrentUserContext } from "../../../contexts/CurrentUserContext.js";
import { ModalEdit, Settings } from '../'
import { ModalContainer } from '../../atoms'
import {useFormValidation} from '../../../utils/Validator'
import { subscriptions, mealsArray } from '../../../constants/constants';
import { useSelector , useDispatch} from 'react-redux'
import {editVisor} from '../../../store/api.js'

export function UsersTable(props) {
  const user = props.user 
  const {recipes, sts} = useSelector(state => state.recipes)
  const { plans } = useSelector(state => state.plans)
  const dispatch= useDispatch()
  const { lib } = useSelector(state => state.lib)
  const { 
    values, 
    handleChange,
  } = useFormValidation({finance: user.finance && user.finance.summ, bonus: user.bonus && user.bonus.summ, dateAcces: user.dateAcces && user.dateAcces, subscriptions: user.subscriptions && user.subscriptions.title});
  const [acces, setAcces] = React.useState(user.acces)
  const [showModal, setShowModal] = React.useState(false)
  const [type, setType] = React.useState()
  const [name, setName] = React.useState()
  const [types, setTypes] = React.useState([])
  const [powerMode, setPowerMode] = React.useState([])
  const [period, setPeriod] = React.useState('everyday')
  const [weekday, setWeekday] = React.useState([])
  const [ingredientsData, setIngredientsData] = React.useState([])
  const [kitchenware, setKitchenware] = React.useState([])
  const [showModalSetting, setShowModalSetting] = React.useState(false)
  const [admin, setAdmin] = React.useState(user.admin);
  const [supervisor, setSupervisor] = React.useState(user.supervisor)
  const currentUser = React.useContext(CurrentUserContext);
  const dateAcces = new Date(user.dateAcces).toLocaleDateString()
  const createDate = new Date(user.createdAt).toLocaleDateString()
  const today = new Date()
  let subscription = subscriptions.find(i=> i.title=== values.subscriptions) 
  const userPlans = plans && plans.filter(f=> f.owner === user._id) 
  const lastLogin = user.lastLogin.length> 0 && new Date(user.lastLogin[user.lastLogin.length-1]).toLocaleDateString()
  let countRecipe = recipes.filter(r=> r.owner === user._id)

  //Заполнение данных из базы при загрузке страницы
React.useEffect(() => {
  const newtype = [...lib.typeFoods]
      newtype.map(i=> {
        let arr = user.settings.typeOfDiet.find(k => k._id === i._id);
        if (arr) {
          let updatedObj = {
            _id: i._id,
            nameRu: i.nameRu ,
            nameEn: i.nameEn,
            desc: i.desc, 
            boolean: arr.boolean,
          }; 
          newtype[newtype.indexOf(i)] = updatedObj
        }
      })
  //const newtype= user.settings.typeOfDiet.some(i=> i.nameEn === 'lactoVegan')
  const newpower= user.settings.powerMode.some(i=> i.dish.some(k=> k.nameRu=== 'Первое блюдо') )
  setIngredientsData(user.settings.stopProducts)
  setWeekday(user.settings.period)
  setTypes(newtype)
  setPeriod(user.settings.periodAn)
  setPowerMode(newpower ? mealsArray : user.settings.powerMode) 
  setKitchenware(user.settings.kitchenware ? user.settings.kitchenware : lib.kitchenware)   
}, [user, lib]);

//Добавление типов питания в массив
const handleCheckBox = (item) => {
  setTypes(() => types.map((c) => c.nameEn === item.nameEn ? item : c));
};

const handleCheckBoxKitchenware = (item) => {
  setKitchenware(() => kitchenware.map((c) => c.nameEn === item.nameEn ? item : c));
};

const handleCheckBoxWeekday = (item) => {
  if(item.nameEn === 'everyday') {
    setWeekday(() => weekday.map((c) => ({
      ...c,
      boolean: item.boolean
    })))
  } else {
    setWeekday(() => weekday.map((c) => c.nameEn === item.nameEn ? item : c))
  }
};

const handleCheckBoxPeriod = (e) => { 
  setPeriod(e.target.value) 
};
 
//Обработка Чекбоксов режима питания
const handleCheckBoxMeals = (item) => {
  setPowerMode(() => powerMode.map((c) => c.nameEn === item.nameEn ? item : c)) 
};

  //Меняем права модератора в системе
  function handlerUserVisor () {
    setSupervisor(!supervisor)
 /*   props.onVisorUser({
      supervisor: !supervisor,
      id: user._id,
    })*/
    dispatch(editVisor({
      supervisor: !supervisor,
      id: user._id,
      current: currentUser.supervisor,
    }))


  }

  //Добавляем права Админа
  function handleAdminUser () {
    setAdmin(!admin)
    props.onAdminUser({
      user,
      admin: !admin,
    })
  }

  //Добавляем права Админа
  function handleAccesUser () {
    setAcces(!acces)
    props.onAccesUser({
      acces: !acces,
      id: user._id,
    })
  }

  //Удаляем пользователя
  function handleDeleteUser () {
     props.onDeleteUser(user)
   }

  //Изменяем данные доступа у пользователя
  function onChangeInput (data, name) {
    setShowModal(true)
    setType(data)
    setName(name)
  }
 
  function hanleClose () {
    setShowModal(false)
    setShowModalSetting(false)
  }
  
//Отправка данных на сервер
  function handleSubmit (e) {
    e.preventDefault();
    let editUser= {
      acces: acces,
      subscriptions: subscription,
      bonus: { 
        summ: values.bonus, 
        recipeActive: user.bonus.recipeActive, 
        sumTraffick: user.bonus.sumTraffick, 
        recipeInActive: user.bonus.recipeInActive},
      dateAcces: values.dateAcces,
      finance: {
        summ: values.finance, 
        receiptTraffic:  user.finance.receiptTraffic, 
        writeDownsTraffic: user.finance.writeDownsTraffic,
        settings: user.settings,
      },
      id: user._id
    }
    
    props.onEditUserData(editUser)
    setShowModal(false)
    setShowModalSetting(false)
  }

  function handleOpenSetting() {
    setShowModalSetting(true)
  }

  function handleSubmitSetting(e) {
    e.preventDefault();
    let editUser= {
      acces: acces,
      subscriptions: subscription,
      bonus: { 
        summ: values.bonus, 
        recipeActive: user.bonus.recipeActive, 
        sumTraffick: user.bonus.sumTraffick, 
        recipeInActive: user.bonus.recipeInActive},
      dateAcces: values.dateAcces,
      finance: {
        summ: values.finance, 
        receiptTraffic:  user.finance.receiptTraffic, 
        writeDownsTraffic: user.finance.writeDownsTraffic, 
      },
      settings: {
        sumFamily: user.settings.sumFamily ? user.settings.sumFamily : 1,
        cookingTime: user.settings.cookingTime,
        stopProducts: user.settings.stopProducts,
        period: user.settings.period,
        periodAn: user.settings.periodAn,
        powerMode,
        typeOfDiet: types,
        kitchenware, 
      },
      id: user._id
    }
    props.onEditUserData(editUser)
    setShowModalSetting(false)
  }
  
  return (

    <div className='table__tr_user' >
      <div className='table__td_recipe'>{user.name} {user.email}</div>
      <div className='table__td_user'>
      <p className='user__item table__acces'><span className='user__item_meaning'>{user.subscriptions === 'undefined' ? 'НЕТ' : user.subscriptions && user.subscriptions.title}</span>
      {currentUser.supervisor && 
          <button className='item__button' onClick={()=>onChangeInput('text', 'subscriptions')}>Изменить</button>
        }
      </p>
        </div>
      <div className='table__td_recipe'>
      <p className='user__item table__acces'> <span className={`user__item_meaning ${user.supervisor ? 'user__yes' : ''}`}>Сурвизор</span>
        {currentUser.supervisor && 
          <button className='item__button' onClick={handlerUserVisor} >Изменить</button>
        }
      </p>
      <p className='user__item table__acces'><span className={`user__item_meaning ${user.acces ? 'user__yes' : ''}`}>Доступ</span>
        {currentUser.supervisor && 
          <button className='item__button' onClick={handleAccesUser}>Изменить</button>
        }
      </p>
      <p className='user__item table__acces'><span className={`user__item_meaning ${user.admin ? 'user__yes' : ''}`}>Админ</span>
        {currentUser.supervisor && 
          <button className='item__button' onClick={handleAdminUser}>Изменить</button>
        }
      </p>
        </div>
   
      <div className='table__td_recipe table__acces'>
      <p className='user__item'><span className='user__item_meaning'>{createDate=== 'undefined' ? today : createDate}  </span></p>
      <p className='user__item'><span className='user__item_meaning'> {dateAcces=== 'undefined' ? today : dateAcces }</span></p>
      
      <p className='user__item'> 
        {currentUser.supervisor && 
          <button className='item__button' onClick={()=>onChangeInput('date', 'dateAcces')}>Изменить</button>
        }
      </p> 
      <p className='user__item'>К-во Вх:<span className='user__item_meaning'>{user.lastLogin.length}</span></p>
      
      <p className='user__item'>Посл.Вх:<span className='user__item_meaning'> {lastLogin}</span></p>
      
        </div>
     
      <div className=' table__td_buttons user__acces'>
      <p className='user__item table__acces'>{user.finance === 'undefined' ? 'НЕТ' : user.finance && user.finance.summ} &#8381;
        {currentUser.supervisor && 
          <button className='item__button' onClick={()=>onChangeInput('number', 'finance')}>Изменить</button>
        }
      </p>
      <p className='user__item table__acces'>{user.bonus === 'undefined' ? 'НЕТ' : user.bonus && user.bonus.summ} Б
        {currentUser.supervisor && 
          <button className='item__button' onClick={()=> onChangeInput('number', 'bonus')}>Изменить</button>
        }
      </p>
        </div>
        <div> 
        <p className='user__item'> 
        {userPlans && userPlans.length} /{countRecipe !== undefined ? countRecipe.length : 0} 
      </p>
        </div>
        <div> 
        <button className='gg-edit' onClick={handleOpenSetting}/>

        </div>
     <ModalContainer 
      isOpen={showModalSetting}
      onClose={hanleClose}
      title='settings'
    > 
       <Settings type='plans' onClose={hanleClose} user={user} /> 
    </ModalContainer>
   
 
    <ModalEdit 
      isOpen={showModal} 
      onClose={hanleClose}
      onChange={handleChange}
      onSubmit={handleSubmit}
      type={type}
      subscriptions={subscriptions}
      value={values[name]}
      name={name}
    />

</div>
  )
   
}


const IngData = (props) => {
  const {ingredients} = useSelector(state => state.ingredients)
  const ingredient = ingredients.find(f => f._id === props.item); 
  return (
    <>
    { ingredient !== undefined ?
      <p className='ingredient__measure_text'>{ingredient.name} </p> 
      :"ингредиент не найден"
    }
    </>
  
  )
}