import React from 'react';
import './Ingredient.css';
import { useSelector } from 'react-redux'

export function Ingredient(props) {
  const item = props.item
  const {ingredients} = useSelector(state => state.ingredients)
  const {lib} = useSelector(state => state.lib)
  const ingredient = ingredients.find(f => f._id === item._id);
  const choisMeasure = lib.measure.find(f => f._id === item.choisMeasure || f.nameRu === item.choisMeasure);
  
  function handleDelete() {
    props.onClick(item._id)
  }
 
  function handleCopy(event) {
    event.preventDefault(); 
    props.copyIngredient(ingredient)
  }
  
  return (
    <div className='add-recipe__type_conteiner'>
      <article className='add-recipe__type_ing'>
        <div className='add-recipe__label add-recipe__label_type_ingredients'>
          <p className='add-recipe__input add-recipe__input_type_ingredients'>
            {ingredient.name} {ingredient.trait}
          </p> 
        </div>
        <div className='add-recipe__label add-recipe__label_type_weight'>
        <input 
          autoComplete="off" 
          disabled={true} 
          type='number' 
          value={item.weight} 
          onChange={props.handleChange} 
          placeholder='Количество' 
          className='add-recipe__input add-recipe__input_type_ingredients' 
          name='weight' 
          id='weight'
        />
        <input 
        value={choisMeasure.nameRu} 
        onChange={props.handleChange} 
        disabled={true} 
        className='add-recipe__input add-recipe__input_type_measure' 
        name='measure' 
        id='measure'>
        </input>
      </div>
    </article>
      <div className='add-recipe__type_icons'>
        <p onClick={handleDelete} className='ingredient__delete' />
        <button className='gg-copy item__link' onClick={handleCopy}></button> 
      </div>
    </div>
)
   
}
