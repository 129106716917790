//Разметка добавления числовых данных

import React from 'react';
import './ConstData.css';
import { DataBlock, ExceptionBlock } from '../../molecules'
import { AddButton } from '../../atoms'
import { useDispatch, useSelector } from 'react-redux'
import { postLib } from '../../../store/api';

export function ConstData (props) {
  const dispatch = useDispatch();  
  const { lib, status } = useSelector(state => state.lib)
 
  function handleAdd() {
    dispatch(postLib({lib:lib, except: [] }))
  }

  return (
    <main className='data'>
      {/*status === 'resolved' &&
      <AddButton 
        onClick={handleAdd}
        type=''
        title='Добавить библиотеку'
  />*/}
      <DataBlock 
        nameRu='Витамины'
        nameEn= 'vitamins'
      />
      <DataBlock 
        nameRu='Бренды'
        nameEn= 'brands'
      />
      <DataBlock 
        nameRu='Тип питания'
        nameEn= 'typeFoods'
      />
      <DataBlock 
        nameRu='Меры веса'
        nameEn= 'measure'
      />
      <DataBlock 
        nameRu='Блюда'
        nameEn= 'dish'
      />
      <DataBlock 
        nameRu='Категории у ингредиентов'
        nameEn= 'catIn'
      />
      <DataBlock 
        nameRu='Инвентарь'
        nameEn= 'kitchenware'
      />

      <ExceptionBlock 
        nameRu='Исключения'
        nameEn= 'except'
      />
    </main>
  )
}