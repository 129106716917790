import React from 'react';
import './SideMenu.css';
import { NavLink} from 'react-router-dom'

export function SideMenu(props) {
  
  return (
    <nav className={`sidebar ${!props.loggedIn ? ('sidebar-hidden') : ''}`}>
      <ul className='sidebar__list'>
      <li className='sidebar__item item__link'><NavLink exact to="/data"  activeClassName="sidebar__active" className="sidebar__nav" >Данные</NavLink></li>
      <li className='sidebar__item item__link'><NavLink exact to="/ingredients"  activeClassName="sidebar__active" className="sidebar__nav" >Ингредиенты</NavLink></li>
      <li className='sidebar__item item__link'><NavLink exact to="/recipes"  activeClassName="sidebar__active" className="sidebar__nav" >Рецепты</NavLink></li>
      <li className='sidebar__item item__link'><NavLink exact to="/users"  activeClassName="sidebar__active" className="sidebar__nav" >Пользователи</NavLink></li>
      <li className='sidebar__item item__link'><NavLink exact to="/comments"  activeClassName="sidebar__active" className="sidebar__nav" >Коментарии</NavLink></li>
      <li className='sidebar__item item__link'><NavLink exact to="/comments"  activeClassName="sidebar__active" className="sidebar__nav" >Коментарии</NavLink></li>
      </ul>
    </nav>
)
   
}