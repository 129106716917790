import React from 'react';
import {useFormValidation} from '../../../utils/Validator'
import { AddFormRecipe, EditFormRecipe} from '../../molecules';
import { ModalContainer } from '../../atoms';  
import { AddEditIngredient } from '../AddEditIngredient/AddEditIngredient'; 
import { useSelector, useDispatch } from 'react-redux'
import { postRecipe, editRecipe } from '../../../store/api'
import Resizer from "react-image-file-resizer";
import { filtrSearchArr } from '../../../utils/utils.js'

export function AddEditRecipe(props) {
  const { 
    values, 
    handleChange,
    setValues,
    resetForm,
    titleError,
  } = useFormValidation({fresh: false});
  const { lib, status } = useSelector(state => state.lib)
  const [capacity, setCapacity] = React.useState(1)
  const [filtrMassiv, setFiltrMassiv] = React.useState([]);
  const [editIngredient, setEditIngredient] = React.useState(false)
  const [copyCard, setCopyCard] = React.useState(false)
  const [ingredient, setIngredient] = React.useState('')
  const [weight, setWeight] = React.useState('')
  const [measure, setMeasure] = React.useState('')
  const [hidden, sethidden] = React.useState(true)
  const [ingredientsData, setIngredientsData] = React.useState([]) //добавленный ингредиент
  const [choiceIngredient, setChoiceIngredient] = React.useState([]) // выбранный ингредиент
  const [step, setStep] = React.useState(3)
  const [imageBlob, setImageBlob] = React.useState([]);//для отправки на сервер в формате base64
  const [imageDesc, setImageDesc] = React.useState([]);//для отправки пошаговых фото на сервер в формате base64
  const {ingredients} = useSelector(state => state.ingredients)
  const [showPopup, setShowPopup] = React.useState(false)
  const [types, setTypes] = React.useState([])
  const [kitchenware, setKitchenware] = React.useState([])
  const [desc, setDesc] = React.useState({})
  const editCard = props.card
  const dispatch = useDispatch()
  const { sts } = useSelector(state => state.recipes)
  const [ingError, setIngError] = React.useState('');
  const [weightError, setWeightError] = React.useState('');
  const [descError, setDescError] = React.useState('');
  const imageError = imageBlob.length ===0 ? 'Добавьте фотографию готового блюда' : '';
 // const typesError =  types.some((c) => c.boolean === true);
  const timeError =  (values.hours ==='' &&  values.minute === '') || ( values.hours === undefined &&  values.minute === undefined) ||
  (values.hours ==='' &&  values.minute === undefined) || (values.hours ===undefined &&  values.minute === '')
  const addIngDisabled = ingredient === '' || weight === '' || 
    measure === '' || measure ==='' || measure ==='none' || ingError !== '' || weightError !== '' 
  const submitDisabled = values.title === undefined || values.title === '' ||
    ingredientsData.length === 0 || desc.length ===0 || imageError || 
    Object.values(desc).length === 0 || descError !== '' || /*!typesError ||*/
    values.category === '' || values.category === undefined || timeError

  //Получение токена при каждом мониторовании
  React.useEffect(() => {
    if(status === 'resolved') {
      setTypes(lib.typeFoods)
      setKitchenware(lib.kitchenware)
    } 
    resetForm()
    setImageBlob([])
    setIngredientsData([])
    setCapacity(1) 
    setDesc([])
    if(editCard) {
      const newtype = editCard.typeOfDiet.some(i=> i.nameEn === 'lactoVegan')
      setValues({
        title: editCard.name,
        category: editCard.category,
        hours: editCard.time / 60 < 1 ? '0' : ((editCard.time - editCard.time % 60) / 60),
        minute: editCard.time % 60,
        author: editCard.link && editCard.link,
        activeCooking: editCard.activeCooking,
        preparation: editCard.preparation,
        advice: editCard.advice,
        fresh: editCard.fresh,
        heat: editCard.frying !==undefined ? editCard.frying : false,
      })
      setTypes(newtype ? lib.typeFoods : editCard.typeOfDiet)
      setKitchenware(editCard.kitchenware.length !== 0 ? editCard.kitchenware : lib.kitchenware)
      setCapacity(editCard.servings)
      setDesc(editCard.description)
      //setIngredientsData(editCard.ingredients) вернуть после изменения все рецептов, перезапись ингредиентов на id
      setIngredientsData(conversion(editCard.ingredients))
      setImageBlob(editCard.image) 
    }
  }, [props.isOpen, editCard]); 

  //Добавление рецепта 
  function addRecipe(data) {
    const timeCounter = `${parseInt(data.values.hours ===undefined || data.values.hours === '' ? 0 : data.values.hours )*60+parseInt(data.values.minute ===undefined || data.values.minute === '' ? 0 : data.values.minute)}`
    const newArr ={
      name: data.values.title,
      nameRu: data.values.title,
      description: data.desc,
      heat: data.values.heat,
      fresh: data.values.fresh,
      category: data.values.category,
      image: data.imageBlob,
      ingredients: data.ingredientsData,
      servings: data.capacity,
      time: timeCounter,
      typeOfDiet: data.types,
      kitchenware: data.kitchenware,
      calories: data.result,
      preparation: data.preparation,
      activeCooking: data.activeCooking,
      advice: data.advice,
      desc:[],
      link: data.values.author === undefined ? '' : data.values.author
    }
    dispatch(postRecipe(newArr))
    if(sts === 'resolved') {
      props.postRecipe()
    }  
  } 
 
  //редактирование рецепта 
  function editRecipes(arr) {
    dispatch(editRecipe(arr))
    if(sts === 'resolved') {
      props.onClose(false)
    }
  }
  
  //добавление новых фотографий
  const handleClick = async (e) => {
    const imgArr = []
    try{
      for(let i=0; i< e.target.files.length; i++) {
        const file = e.target.files[i];
        const image = await resizeFile(file);
        imgArr.push(image)
      }
    } catch (err) {
      console.log(err)
    }
    setImageBlob(imageBlob => [...imageBlob, ...imgArr])
  }

  //Обработка пошагового описания с фото
  const handleClickDesc = async (e) => {
    let imgArr 
    try{
      for(let i=0; i< e.target.files.length; i++) {
        const file = e.target.files[i];
        const image = await resizeFile(file);
        imgArr = image
      }
    } catch (err) {
      console.log(err)
    }
    setImageDesc(imageDesc => [...imageDesc,{ image: imgArr, desc: ''}])
  }

  //сжатие и изменение формата изображения
  const resizeFile = async (file) => {
    return new Promise(resolve => {
      Resizer.imageFileResizer(
        file, 
        600, 
        600,  
        'JPEG', 
        70, 
        0,
        uri => {
          resolve(uri);
        }, 'base64' )
      });
  };
 
  //Добавление шага с описанием
  function handleNewStep() {
    if(step < 10) {
      setStep(step+1)
    } else {
      setStep(step)
    }
  }

  //Удаление шага с описанием
  function handleDeleteStep() {
    if(step > 2) {
      setStep(step-1)
    } else {
      setStep(step)
    }
  }

   //Подстановка автоматически значения в инпут из выбранного
   function handleAutoText(ing) {
    setChoiceIngredient(ing._id)
    setIngredient(`${ing.name} ${ing.trait}`)
    sethidden(false)
  }

  //добавление ингредиентов в массив
  function handleAddIng() {
    setIngredient('')
   // setChoiceIngredient({id: choiceIngredient, weight: weight, choisMeasure: measure})
    setIngredientsData([ ...ingredientsData, {_id: choiceIngredient, weight: weight, choisMeasure: measure}]);
    setMeasure('')
    setWeight('')
  }

  // Переписание ингредиентов в рецептах на id
  function conversion(data) {
    let arr=[]
    data.map((item) => {  
      const choisMeasure = lib.measure.find(f => f._id === item.choisMeasure || f.nameRu === item.choisMeasure);
      arr.push({
        _id: item._id, 
        weight: item.weight, 
        choisMeasure: choisMeasure.nameRu,
      }) 
    })
    return arr
  }

  //удаление ингредиентов
  function handleDelete(id) {
    setIngredientsData((cards)=> cards.filter((c) => c._id !== id));
  }

  //Изменение веса ингредиентов
  function handleChangeInputWeight(e) {
    setWeight(e.target.value)
    errorMessage(e)
  }

  //Изменение меры ингредиентов
  function handleChangeMeasure( e) {
    setMeasure(e.target.value)
  }

  //Изменение видимости выпадающего списка ингредиентов
  function handleFocus() {
    sethidden(false)
  }
 
  //Подсчет калорий в ингридиентах с учетом веса
  let result = ingredientsData.reduce(function(acc,v, index){
    const ingredient = ingredients.find(f => f._id === v._id);
    const totalWeights = ingredient.measure.find(i=> i.nameRu === v.choisMeasure)
    const {purified, cooking, frying} = ingredient
    if(totalWeights !== undefined) {
      const totalWeight = totalWeights.ratio*v.weight;
      acc.totalMeasure += totalWeight
      acc.protein += ingredient.calories.protein*totalWeight/100;
      acc.fats += ingredient.calories.fats*totalWeight/100;
      acc.carb += ingredient.calories.carb*totalWeight/100;
      acc.calories += ingredient.calories.calories*totalWeight/100;
      const purifiedWeight = totalWeight - (purified*totalWeight/100)
      if(values.heat) {
        const cookingWeight = purifiedWeight - (cooking*purifiedWeight/100)
        if(cooking === 0) {
          const fryingWeight = purifiedWeight - (frying*purifiedWeight/100)
          acc.totalWeight += fryingWeight
        } else {
          acc.totalWeight += cookingWeight
        }
      } else {
        acc.totalWeight += purifiedWeight
      }
      return acc;
    }
  }, {protein: 0, fats: 0, carb: 0, calories:0, totalMeasure: 0, totalWeight: 0});
 
  //Добавление типов питания в массив
  const handleCheckBox = (item) => {
    const type = [...types]
    const kitchen = [...kitchenware]
    setTypes(() => type.map((c) => c.nameEn === item.nameEn ? item : c));
    setKitchenware(() => kitchen.map((c) => c.nameEn === item.nameEn ? item : c))
  };

  //счетчик членов семьи в плюс
  function handleCountClickPlus() {
    setCapacity(capacity+1) 
  }

  //счетчик членов семьи в минус
  function handleCountClickMinus() {
    if (capacity > 1) {
      setCapacity(capacity-1)
    }
  }
 
//отправка данных при сабмите
  function handleAddSubmit(event) {
    event.preventDefault();
    addRecipe({values, ingredientsData, desc, capacity, types, kitchenware, result, imageBlob })
  }
  
  //отправка данных при сабмите
  function handleEditSubmit(event) {
    event.preventDefault(); 
    editRecipes({values, ingredientsData, desc, capacity, types, kitchenware, result, imageBlob, id: editCard._id, })
  }

  //Обработка ошибки введенных ингредиентов
  const errorMessage = (e) => {  
    const {name, value} = e.target
    switch(name) {
      case 'ingredients': 
        if(value.length === 0) {
          setIngError('Поле не может быть пустым')
        } else {
          const re = /^[А-Яа-я]/;
          return re.test(String(value).toLowerCase()) ? setIngError('') : setIngError('Введите название кириллицей')
        }
      break;
      case 'weight': 
      if(value.length ===0) { 
        setWeightError('Поле не может быть пустым')
      } else if(value > 1000) {
        setWeightError('Максимальное значение 1000г, измените меру на кг')
      } else {
        setWeightError('')
      }
      break;
      case 'step1': 
      if(value.length ===0) { 
        setDescError('Заполните хотя бы 1 шаг приготовления')
      } else if(value > 5000) {
        setDescError('Максимальное значение 5000 знаков, Перенесите описание на след шаг')
      } else {
        const re = /^[А-Яа-я]/;
        return re.test(String(value).toLowerCase()) ? setDescError('') : setDescError('Введите описание кириллицей')
      }
      break;
    }
  }
   
  function handleCopy(card) {
    setShowPopup(true)
    setEditIngredient(card)
    setCopyCard(true)
  }

  //Изменение инпута ингредиентов
  function handleChangeInput(e) {
    handleFiltrClick(e.target.value)
    setIngredient(e.target.value)
    errorMessage(e)
    sethidden(true)
  } 

  //Поиск в инпуте по ингридиенту
  function handleFiltrClick(keyword) {
  
    if(keyword!=='') {
    const newMassiv = filtrSearchArr(ingredients, keyword, 'ingredient')
    setFiltrMassiv(newMassiv)
    } else {
      setFiltrMassiv([])
    }
  }

  // Изменение checkbox
  function handleCheckChange(e) {
    console.log(e.target.value)
  }
  //Изменение шагов описания
  function handleChangeDesc(e) {
    const {name, value} = e.target
    setDesc({...desc, [name]: value});
    errorMessage(e)
  } 
   
  function handleDeleteHeritageImage(image) {
  
    setImageBlob((imageBlob)=> imageBlob.filter((c) => c !== image));
  }

  function handleDeleteImgDesc(image) {
    setImageDesc((imageDesc)=> imageDesc.filter((c) => c !== image));
  }

  function handleAddIngredient() {
    setShowPopup(true)
  }

  function handleCloseModalIng() {
    setShowPopup(false)
  }

  return (
    <>
     <ModalContainer 
        isOpen={props.isOpen}
        onClose={props.onClose}
        head='Добавить новый рецепт'
        title='add-recipe'
      > 
    <AddFormRecipe 
      onSubmit={handleAddSubmit}
      lib={lib.length > 0 && lib}
      types={types}
      heat={values.heat}
      fresh={values.fresh}
      imageDesc={imageDesc}
      advice={values.advice}
      preparation={values.preparation}      
      activeCooking={values.activeCooking}
      kitchenware= {kitchenware}
      addIngredient={handleAddIngredient}
      //isOpenIng={showModal} 
      onCloseModalIng={handleCloseModalIng}
      title={values.title}
      category={values.category}
      hours={values.hours}
      minute={values.minute}
      author={values.author}
      onChange={handleChange} 
      step={step}
      head='Добавить новый рецепт'
      handleCountClickMinus={handleCountClickMinus}
      handleCountClickPlus={handleCountClickPlus}
      capacity={capacity}
      ingredientsData={ingredientsData}
      handleDelete={handleDelete}
      filtrMassiv={filtrMassiv}
      handleChangeInput={handleChangeInput}
      handleAutoText={handleAutoText}
      hidden={hidden}
      ingredient={ingredient}
      measure={measure}
      weight={weight}
      handleCheckChange={handleCheckChange}
      handleDeleteImgDesc={handleDeleteImgDesc}
      handleChangeMeasure={handleChangeMeasure}
      handleChangeInputWeight={handleChangeInputWeight}
      handleFocus={handleFocus}
      choiceIngredient={choiceIngredient}
      handleAddIng={handleAddIng}
      handleCheckBox={handleCheckBox}
      handleChangeDesc={handleChangeDesc}
      desc={desc}
      onClickDesc={handleClickDesc}
      handleDeleteStep={handleDeleteStep}
      handleNewStep={handleNewStep}
      descStep={values.descStep}
      onClose={props.onClose}
      imageBlob={imageBlob}
      handleDeleteHeritageImage={handleDeleteHeritageImage}
      onClick={handleClick}
      submitDisabled={submitDisabled}
      ingError={ingError}
      weightError={weightError}
      addIngDisabled={addIngDisabled}
      titleError={titleError}
      descError={descError}
      imageError={imageError}
      copyIngredient={handleCopy}
    />
   </ModalContainer>
   <ModalContainer 
        isOpen={props.isEditOpen}
        onClose={props.onClose}
        head='Редактировать рецепт'
        title='edit-recipe'
      > 
    <EditFormRecipe 
      onSubmit={handleEditSubmit}
      lib={lib.length > 0 && lib}
      title={values.title}
      advice={values.advice}
      imageDesc={imageDesc}
      handleCheckChange={handleCheckChange}
      handleDeleteImgDesc={handleDeleteImgDesc}
      preparation={values.preparation}
      activeCooking={values.activeCooking}
      kitchenware= {kitchenware}
      category={values.category}
      heat={values.heat}
      fresh={values.fresh}
      hours={values.hours}
      minute={values.minute}
      author={values.author}
      onChange={handleChange} 
      step={step}
      addIngredient={handleAddIngredient}
      handleCountClickMinus={handleCountClickMinus}
      handleCountClickPlus={handleCountClickPlus}
      capacity={capacity}
      ingredientsData={ingredientsData}
      handleDelete={handleDelete}
      filtrMassiv={filtrMassiv}
      handleChangeInput={handleChangeInput}
      handleAutoText={handleAutoText}
      hidden={hidden}
      ingredient={ingredient}
      measure={measure}
      weight={weight}
      handleChangeMeasure={handleChangeMeasure}
      handleChangeInputWeight={handleChangeInputWeight}
      handleFocus={handleFocus}
      choiceIngredient={choiceIngredient}
      handleAddIng={handleAddIng}
      types={types}
      handleCheckBox={handleCheckBox}
      handleChangeDesc={handleChangeDesc}
      desc={desc}
      handleDeleteStep={handleDeleteStep}
      handleNewStep={handleNewStep}
      isOpen={props.isEditOpen}
      descStep={values.descStep}
      imageBlob={imageBlob}
      handleDeleteHeritageImage={handleDeleteHeritageImage}
      onClick={handleClick} 
      submitDisabled={submitDisabled}
      ingError={ingError}
      weightError={weightError}
      addIngDisabled={addIngDisabled}
      descError={descError}
      imageError={imageError}
      head='Редактировать рецепт'
      copyIngredient={handleCopy}
    />
  
    </ModalContainer>
      <AddEditIngredient 
      onClose={handleCloseModalIng} 
      onError={props.onError} 
      postIng={props.postIng} 
      isOpen={showPopup}  

      //isOpenEdit={showModalEdit} 
      card={editIngredient && editIngredient}
      copyCard={copyCard && copyCard}
    />
    </>
  )
}