import React from 'react';
 
export function CheckBox (props) {
  const {heat, fresh} = props
  return (
    <>
    <label className='meals__label' htmlFor='heat'>
      <input type="checkbox" checked={heat} className='meals__checkbox' onChange={props.onChange} name='heat' id='heat' />
      <span className="visible-checkbox item__link"></span>
      Термообработка
    </label>
    <label className='meals__label' htmlFor='fresh'>
      <input type="checkbox"  checked={fresh}  className='meals__checkbox' onChange={props.onChange} name='fresh' id='fresh' />
      <span className="visible-checkbox item__link"></span>
      Употребить свежеприготовленным
    </label>
    </>
  ) 
} 